import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { Label } from 'semantic-ui-react'

import { CardType, FORMAT } from 'types/deck'

type Props = {
  card: CardType
}

const CanlanderPointsTag = ({ card }: Props) => {
  const deckFormat = useAppSelector(state => state.deck.format)

  if (deckFormat !== FORMAT.CANLANDER) return null
  if (!card.canlanderPoints) return null

  return (
    <Label
      style={{ borderRadius: '0px 11px 0px 0px', overflow: 'hidden', opacity: 0.8 }}
      color="red"
      title="Canadian Highlander points"
      corner="right"
      size="mini">
      <div style={{ textAlign: 'right', padding: 7, fontSize: '125%', opacity: '1', color: 'white' }}>
        {card.canlanderPoints}
      </div>
    </Label>
  )
}

export default CanlanderPointsTag
