import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga4'

import { useAppSelector } from 'redux/hooks'
import { selectCardMap } from 'redux/deck/selectors'

import {
  Tab as TabType,
  generateTabDOMId,
  ArchidektSearchMeta,
  ScryfallSearchMeta,
  ManabaseGeneratorMeta,
} from 'types/searchV2'
import { CardType, defaultCard } from 'types/deck'
import { CardListResponse } from 'services/apiTypes/card.types'

import CardModalStandalone from 'components/card/CardModal'
import CardEditionsGridOverlay from 'components/card/CardEditionsGridOverlay'
import ScryfallSearchForm from '../searchFormElements/scryfallSearchForm'
import ArchidektSearchForm from '../searchFormElements/archidektSearchForm'
import CardResultsGrid from '../cardLayouts/cardResultsGrid'
import ToastService from 'services/toast.service'
import CardService from 'services/card.service'
import EdhRecFrom from '../searchFormElements/edhRecsForm'
import PhatButton from 'components/formElements/PhatButton'
import ManabaseGeneratorForm from 'components/cardSearchPanel/searchFormElements/manabaseGeneratorForm'
import SpellbookComboProvider from 'components/cardSearchPanel/searchFormElements/spellbookComboProvider'

import { modifySearchResults } from 'utils/searchModfiyResults'

import styles from './tab.module.scss'
import saveService from 'services/save.service'

type Props = {
  tab: TabType
  onTabUpdate: (updatedTab: TabType) => void
  onDragStart?: () => void
  onDragStop?: () => void
  className?: string
  active: boolean
  initailResults?: CardType[]
}

// Used to display all tab content (only ever displays the active tab at a time @see SearchV2)
const Tab = ({ tab, onTabUpdate, onDragStart, onDragStop, active, initailResults }: Props) => {
  const resultsRef = useRef<CardType[]>()

  const [results, setResults] = React.useState(initailResults ?? [])
  const [next, setNext] = React.useState<string | null>(null)
  const [activeCardId, setActiveCardId] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)
  const [editionsAsDefault, setEditionsAsDefault] = useState(false)
  const [viewingCardMenu, setViewingCardMenu] = useState(false)

  const cardMap = useAppSelector(selectCardMap)

  // When we hide the pannel, switch back to the list view
  // Doing this prevents getting into a wonky state if you edit a card in the deck, that's also in search, and open
  useEffect(() => {
    if (active) return

    setEditionsAsDefault(false)
    setViewingCardMenu(false)
  }, [active])

  useEffect(() => {
    resultsRef.current = results
  }, [results])

  useEffect(() => {
    if (tab.type === 'spellbookCombo') {
      setResults([])
      setNext(null)
      setActiveCardId(null)
    }
  }, [tab.type])

  useEffect(() => {
    if (!initailResults) return

    setResults(initailResults)
  }, [initailResults])

  const handleUpdateCard = (currentCardId: string | null, updatedCard: CardType) => {
    if (!currentCardId) return

    // The reason we update the reuslts is so if you edit a card that isn't in the deck
    // eg: Making something foil (to check price), changing an edition (to see what it looks like), etc
    const upToDateResults = resultsRef.current || results
    const updatedResults = [...upToDateResults]
    const updatedIndex = upToDateResults.findIndex(card => card.id === currentCardId)

    updatedResults[updatedIndex] = updatedCard

    setResults(updatedResults)
    setActiveCardId(updatedCard.id)

    // If we don't have a quantity, and it's not in the cardMap, don't update anything
    if (!updatedCard.qty && !cardMap[currentCardId]) return

    updatedCard.otherPrintingQty = 0 // force this to be 0 before adding/ modifying the card in the deck

    // prettier-ignore
    if (updatedCard.qty && !cardMap[currentCardId]) ReactGA.event({ category: 'Deck', action: 'Added card from search' })

    // We strip the category info for cards in search. We can add that back when we go to add the card to the deck
    // The stripping happens so when a user sets a category, we auto add the card to the deck
    if (updatedCard.qty && updatedCard.globalCategories.length && !updatedCard.categories.length)
      updatedCard.categories = [...updatedCard.globalCategories]

    saveService.save(updatedCard, [], true)
  }

  const handleRecieveResponse = (
    res: CardListResponse,
    meta: ArchidektSearchMeta | ScryfallSearchMeta | ManabaseGeneratorMeta,
  ) => {
    const updatedTab = { ...tab, meta }

    // @ts-ignore - might be able to fix this
    // Caused by this componenent doing work for both archisearch and scryfall search
    onTabUpdate(updatedTab)
    setResults(res.cards)
    setNext(res.next)

    setLoading(false)
  }

  const handleRequestFailure = (message?: string) => {
    ToastService.create(message || 'Un unexpected error occured while searching', 'Card Search', 'error')

    setLoading(false)
  }

  const handleLoadNext = () => {
    setLoading(true)

    CardService.scryfallSearch('', next)
      .then(res => {
        setResults([...results, ...res.cards])
        setNext(res.next)
      })
      .catch(handleRequestFailure)
      .finally(() => setLoading(false))
  }

  const deck = Object.values(cardMap)

  // Modifying results to do 1 of 3 things here
  //   1) If the card is in the deck (by name, but not edition), return the searched card with an appended qty of other printings in the deck
  //   2) If the card is in the deck (by exact id), replace the searched card with the card in the deck
  //   3) If no printings of the card are in the deck at all, just return the searched card with forced qty of 0 (we set the 0 so if the card is ever removed from the deck, it sets the qty back to 0 in search)
  const modifiedResultsAndDeck = modifySearchResults(deck, results)

  const activeCard = modifiedResultsAndDeck.find(resultOrDeckCard => resultOrDeckCard.id === activeCardId) || null

  // Prevents locking the search tab if we somehow get into a wonky state
  // If we attempt to render without an appropriete rendered card, send the user back to the grid view
  if (!activeCard && activeCardId) {
    setActiveCardId(null)
  }

  const activeCardIndex = modifiedResultsAndDeck.findIndex(card => card.id === activeCardId)

  const previousCard = results.find((previousCard, index) => index === activeCardIndex - 1)
  const nextCard = results.find((nextCard, index) => index === activeCardIndex + 1)

  if (!active) return null

  return (
    <>
      <div className={styles.container}>
        {tab.type === 'archidektSearch' && (
          <ArchidektSearchForm
            submitText="Search"
            mainInputId={generateTabDOMId(tab.id)}
            noAutoSearch={modifiedResultsAndDeck.length > 0 || tab.noAutoSearch}
            searchOptions={tab.meta}
            onSubmitted={() => setLoading(true)}
            onRequestFailed={() => setLoading(false)}
            onResponseRecieved={handleRecieveResponse}
          />
        )}

        {tab.type === 'scrySearch' && (
          <ScryfallSearchForm
            submitText="Search"
            mainInputId={generateTabDOMId(tab.id)}
            noAutoSearch={modifiedResultsAndDeck.length > 0 || tab.noAutoSearch}
            searchString={tab.meta.query}
            smartFilters={tab.meta.smartFilters}
            onSubmitted={() => setLoading(true)}
            onRequestFailed={() => setLoading(false)}
            onResponseRecieved={handleRecieveResponse}
          />
        )}

        {tab.type === 'edhRecs' && (
          <EdhRecFrom
            noAutoSearch={modifiedResultsAndDeck.length > 0 || tab.noAutoSearch}
            alreadyHasResults={modifiedResultsAndDeck.length > 0}
            onSubmitted={() => setLoading(true)}
            onRequestFailed={handleRequestFailure}
            onResponseRecieved={cards => {
              setResults(cards)
              setLoading(false)
              setNext(null)
            }}
          />
        )}

        {tab.type === 'manabaseGenerator' && (
          <ManabaseGeneratorForm
            noAutoSearch={modifiedResultsAndDeck.length > 0 || tab.noAutoSearch}
            alreadyHasResults={modifiedResultsAndDeck.length > 0}
            onSubmitted={() => setLoading(true)}
            onRequestFailed={handleRequestFailure}
            onResponseRecieved={handleRecieveResponse}
          />
        )}

        {tab.type === 'spellbookCombo' && (
          <SpellbookComboProvider
            noAutoSearch={modifiedResultsAndDeck.length > 0 || tab.noAutoSearch}
            alreadyHasResults={modifiedResultsAndDeck.length > 0}
            onSubmitted={() => setLoading(true)}
            onRequestFailed={() => setLoading(false)}
            onResponseRecieved={() => setLoading(false)}
            handleCardUpdated={handleUpdateCard}
            onDragStart={onDragStart}
            onDragStop={onDragStop}
          />
        )}

        <CardResultsGrid
          allowDragging={true}
          loading={loading}
          className={styles.grid}
          cards={modifiedResultsAndDeck}
          onCardClick={(card, forceAllLayoutsView) => {
            setActiveCardId(card.id)

            if (forceAllLayoutsView) setEditionsAsDefault(true)
            else setViewingCardMenu(true)
          }}
          onCardUpdated={handleUpdateCard}
          onDragStart={onDragStart}
          onDragStop={onDragStop}
        />

        {next && !loading && (
          <div className={styles.loadMore}>
            <PhatButton onClick={handleLoadNext}>Load More Results</PhatButton>
          </div>
        )}
      </div>

      <CardModalStandalone
        open={activeCardId !== null && viewingCardMenu}
        card={activeCard || defaultCard}
        onCardUpdated={updatedCard => handleUpdateCard(activeCardId, updatedCard)}
        onClose={() => setViewingCardMenu(false)}
        nextPrevControls={{
          previous: {
            label: previousCard?.name,
            onClick: () => setActiveCardId(previousCard?.id || null),
            disabled: !previousCard,
          },
          next: {
            label: nextCard?.name,
            onClick: () => setActiveCardId(nextCard?.id || null),
            disabled: !nextCard,
          },
        }}
      />

      <CardEditionsGridOverlay
        open={activeCardId !== null && editionsAsDefault}
        card={activeCard || defaultCard}
        onCardSelected={updatedCard => handleUpdateCard(activeCardId, updatedCard)}
        onClose={() => setEditionsAsDefault(false)}
        nextPrevControls={{
          previous: {
            label: previousCard?.name,
            onClick: () => setActiveCardId(previousCard?.id || null),
            disabled: !previousCard,
          },
          next: {
            label: nextCard?.name,
            onClick: () => setActiveCardId(nextCard?.id || null),
            disabled: !nextCard,
          },
        }}
      />
    </>
  )
}

export default Tab
