import React from 'react'

import { useAppSelector } from 'redux/hooks'

import SaveService from 'services/save.service'

import { FORMAT, OWNED } from 'types/deck'

import Icon from 'components/elements/Icon'
import CursorCardWrapper from 'components/card/CursorCardWrapper'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import DraggableBasicCard from 'components/card/DraggableBasicCard'
import ColorTagCssDropdown from 'components/card/controlsAndInfo/colorTagCssDropdown'
import DeckCardWrapper, { DeckCardWrapperChildProps } from 'components/card/deckCards/deckCardWrapper'
import { manaArrayToElement } from 'components/misc/customIcons/ManaSymbol/helpers'
import { setCodeToElement } from 'components/misc/customIcons/SetSymbols'
import { generateContextOptions } from 'components/card/deckCards/deckCardContextMenu'

import { getPriceStringAndUrl } from 'utils/Price'

import styles from './textViewCard.module.scss'
import globalVariables from 'assets/scss/variables.module.scss'

type Props = {
  cardId: string
  stackName?: string
}

const TextViewCard = ({ cardId, stackName }: Props) => (
  <DeckCardWrapper cardId={cardId} stackName={stackName} component={Card} />
)

export default TextViewCard

const Card = ({ card, stackName, localCardData, deckData, actions }: DeckCardWrapperChildProps) => {
  const textColumns = useAppSelector(state => state.cacheables.textColumns)
  const priceSource = useAppSelector(state => state.active.priceSource)
  const multiSelectedIds = useAppSelector(state => state.active.multiSelectedIds)
  const isMobile = useAppSelector(state => state.active.isMobile)
  const deckFormat = useAppSelector(state => state.deck.format)

  // prettier-ignore
  const cardType = `${card.superTypes.join(' ')} ${card.types.join(' ')} ${card.subTypes.length > 0 ? ' - ' + card.subTypes.join(' ') : ''}`
  const canViewDragHandles = localCardData.ownsDeck && !isMobile
  const priceObj = getPriceStringAndUrl(card, priceSource[0], card.modifier !== 'Normal')

  const collectionColor =
    card.owned === OWNED.NO
      ? 'transparent'
      : card.owned === OWNED.PRINTING
      ? globalVariables.quantityBlue
      : globalVariables.quantityGreen

  return (
    <CursorCardWrapper
      className={`
      ${styles.card}
      ${localCardData.cardError && styles.cardError}
      ${localCardData.isMultiSelected && styles.selected}
      ${localCardData.antiHighlight && styles.antiHighlight}
    `}
      skipOnTouch
      flipped={localCardData.flipped}
      card={card}>
      <span className={`${styles.leftContent} ${localCardData.inSecondaryCategory && styles.dimmed}`}>
        {textColumns.dragHandle && canViewDragHandles && (
          <DraggableBasicCard
            card={card}
            dragItem={{
              card,
              stackName,
              multiSelectedIds: localCardData.isMultiSelected ? multiSelectedIds : undefined,
            }}>
            <span className={styles.dragHandle}>
              <Icon name="bars" />
            </span>
          </DraggableBasicCard>
        )}
        <button className={styles.button} onClick={actions.cardClick} onContextMenu={actions.rightClick}>
          {localCardData.quantity}
          {card.companion && <Icon title="Companion" name="paw" />}
          <span className={styles.cardName}>{card.name}</span>
        </button>
      </span>

      <span className={styles.rightContent}>
        {textColumns.type && cardType}
        {textColumns.finishes && <span className={`${styles.finishes} ${card.modifier !== 'Normal' && styles.foil}`} />}

        {deckFormat === FORMAT.CANLANDER && textColumns.canlanderPoints && (
          <span style={{ opacity: card.canlanderPoints ? 1 : 0.2 }}>{card.canlanderPoints || '0'}</span>
        )}
        {textColumns.manaCost && manaArrayToElement(card, styles.manaCost)}
        {textColumns.setSymbol && setCodeToElement(card.setCode, card.rarity, styles.setSymbol)}
        {textColumns.price && (
          <a href={priceObj.url} className={styles.shoppingUrl} rel="noopener noreferrer" target="_blank">
            {priceObj.price}
          </a>
        )}

        {textColumns.setName && <span>{card.set}</span>}
        {textColumns.collectionInfo && (
          <span className={styles.collectionDot} style={{ backgroundColor: collectionColor }} />
        )}

        {textColumns.colorTag && (
          <ColorTagCssDropdown
            card={card}
            onCardChange={updatedCard => SaveService.save(updatedCard)}
            disabled={localCardData.anyMultiSelected}
          />
        )}

        {textColumns.extraOptions && (
          <ArchidektDropdown
            triggerClassName={localCardData.anyMultiSelected ? styles.hideDropdown : undefined}
            menuClassName={styles.dropdown}
            options={generateContextOptions({ card, localCardData, actions, stackName, deckData, extraData: {} })}
          />
        )}
      </span>
    </CursorCardWrapper>
  )
}
