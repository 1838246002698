import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { useCookies } from 'react-cookie'

import { ROLES } from 'types/user'

import Icon from 'components/elements/Icon'
import Link from 'components/elements/Link'
import { Spacer } from 'views_DEPRICATED/Pages/style'

import styles from './inlineAd.module.scss'

export const AD_SIZES = {
  banner: { width: 728, height: 90 },
  longBanner: { width: 970, height: 90 },
  billboard: { width: 970, height: 440 },
  rectangle: { width: 300, height: 250 },
  mobileBanner: { width: 320, height: 50 },
  skyscraper: { width: 160, height: 600 },
  halfPage: { width: 300, height: 600 },
}

type Props = {
  id: string
  banner?: boolean
  longBanner?: boolean
  billboard?: boolean
  rectangle?: boolean
  mobileBanner?: boolean
  skyscraper?: boolean
  halfPage?: boolean
  sticky?: [number, number]
  stickyAt?: number
  hideAt?: number
  removeOnNoAd?: boolean
  renderVisibleOnly?: boolean
  top?: boolean
  bottom?: boolean
  allowTallMobile?: boolean
  leaderboard?: boolean
}

/**
 * This component handles the styling as well as creating the nitro ads on rendering or if the size of page changes.
 *
 * sticky - this is an optional prop that a [x, y] pixels from the right and top before an ad is supposed to stick
 * hideAt - pass in the pixel width of the page at which to hide the ad (passed to media query)
 */
const InlineAd: React.FC<Props> = ({
  id,
  sticky,
  hideAt,
  banner,
  longBanner,
  billboard,
  rectangle,
  mobileBanner,
  skyscraper,
  halfPage,
  removeOnNoAd,
  stickyAt = 0,
  top = false,
  bottom = false,
  allowTallMobile = false,
  leaderboard,
}: Props) => {
  const sizes: Array<keyof typeof AD_SIZES> = []

  if (banner) sizes.push('banner')
  if (longBanner) sizes.push('longBanner')
  if (billboard) sizes.push('billboard')
  if (rectangle) sizes.push('rectangle')
  if (mobileBanner) sizes.push('mobileBanner')
  if (skyscraper) sizes.push('skyscraper')
  if (halfPage) sizes.push('halfPage')

  if (!sizes.length) sizes.push('banner')

  const [{ tbTier: tier, tbR: roles = [] }] = useCookies(['tbTier', 'tbR'])

  const isMobile = useAppSelector(state => state.active.isMobile)

  let { height, width } = AD_SIZES[isMobile ? 'mobileBanner' : sizes[sizes.length - 1]]

  if (isMobile && allowTallMobile) height = 300

  const hidesAds = roles.includes(ROLES.AD_FREE) || Number(tier)

  if (hidesAds && !removeOnNoAd) return <Spacer />
  if (hidesAds && removeOnNoAd) return null

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: sticky ? `${width}px` : '100%',
        height: sticky ? '100%' : `${height}px`,

        marginTop: top ? 0 : '25px',
        marginBottom: bottom ? 0 : '25px',

        ...(sticky ? { display: 'unset', position: 'absolute', right: `${sticky[0]}px`, top: `${stickyAt}px` } : {}),
        ...(hideAt ? { [`@media (max-width: ${hideAt}px)`]: { display: 'none' } } : {}),
      }}>
      <div
        style={{
          width: '100%',
          height: `${height}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: sticky ? `${width}px` : 'unset',
          position: 'relative',
          ...(sticky ? { position: 'sticky', top: '50px' } : {}),
        }}
        className={leaderboard ? `mv_slot_target ${styles.hideOnMobile}` : undefined}
        data-slot={leaderboard ? 'LeaderboardMobile' : undefined}
        id={id}>
        <div className={styles.patreonHidden}>
          <Link
            className={styles.patreonLink}
            href="https://www.patreon.com/archidekt"
            rel="noopener noreferrer"
            target="_blank">
            <Icon style={{ color: 'rgb(255, 66, 77)' }} name="patreon" /> Please consider supporting our Patreon to
            remove ads.
          </Link>
        </div>
      </div>
    </div>
  )
}

export default InlineAd
