export const ERRORS = {
  // Deck Sizes
  DECK_SIZE: 'Decks need at least 60 cards to be legal.',
  SIDEBOARD_SIZE: `A deck's sideboard can have no more than 15 cards.`,
  CANLANDER_DECKSIZE: 'Decks need at least 100 cards to be legal',

  // Size
  SINGLETON: 'You may only have one copy of a card in a singleton format -',
  MAX_COUNT: 'You may only have four of any one card -',

  BANNED: 'This card is banned in this format -',
  NOT_LEGAL: 'This card is not legal in this format -',
  PRERELEASED_CARD_EDH: 'This card is not "technically" legal until its official prerelease date -',

  // Commander
  REQUIRE_COMMANDER: 'An EDH deck must have a Commander.',
  CREATURE_COMMANDER: 'Your commander must be a creature.',
  LEGENDARY_COMMANDER: 'Your commander must be a legendary.',
  PARTNER_COMMANDERS: 'Only partner commanders can be paired together',
  PARTNER_WITH_COMMANDERS: '"Partner with" commanders must be partnered with their partner.',
  SINGLE_COMMANDER: 'Max of one Commander (Two if they both have Partner).',
  OUTSIDE_COLOR_IDENTITY: "You have a card outside of your commander's color identity -",
  COMMANDER_SIZE: 'Commander decks need to have exactly 100 cards.',
  ADITIONAL_COLOR_COMMANDER:
    'Your commander can only add one color to the total identity. It looks like you have more than one color outside your provided color identity',

  // Brawl
  BRAWL_SIZE: 'Brawl decks need to have exactly 60 cards.',
  REQUIRE_BRAWL_COMMANDER: 'In Brawl, your commander must be a creature or a Planeswalker.',
  SINGLE_BRAWL_COMMANDER: 'You may only have once commander.',

  // Historic Brawl
  HISTORIC_BRAWL_SIZE: 'Historic Brawl decks need to have exactly 100 cards.',

  // Vintage
  RESTRICTED: 'You may only have one copy of the card in your deck -',

  // Oathbreaker
  SINGLE_OATHBREAKER: 'You may only have one Oathbreaker (usually) -',
  SINGLE_SIGNATURE_SPELL: 'You may only have one Siganture Spell (usually) -',
  OATHBREAKER_SIZE: 'Oathbreaker decks need to have exactly 60 cards.',
  PARTNER_OATHBREAKERS: 'Only partner oathbreakers can be paired together',
  PARTNER_SIGNATURE_SPELL: 'Each Partner must have their own signature spell -',

  // 1v1 Commander
  BANNED_AS_COMMANDER: 'This card is banned as a commander -',

  // Pauper EDH
  VALID_PAUPER_COMMANDER: 'This card is not a valid commander is Pauper EDH. It must be a creature and uncommon -',
  NO_UNCOMMONS_IN_99: 'An uncommon creature may only be used in Pauper EDH if it is your commander -',

  // Companion:
  UNKNOWN_COMPANION: "Unknown companion! We're skipping the legality check for this companion -",
  COMPANION_ERROR: 'Companion conditions not met for ',
  MULTIPLE_COMPANIONS: 'A deck may only contain one set companion, checking last found companion for legality',

  GLADIATOR_SIZE: 'Gladiator decks need to be exactly 100 cards.',

  // Canlander
  OVER_MAX_POINTS_CANLANDER: 'Canadian Highlander decks may only have a maximum of up to 10 points.',
}

export const CARD_QTY_OVERRIDE: Record<string, number> = {
  'Seven Dwarves': 7,
  'Nazgûl': 9,
  Plains: -1,
  Island: -1,
  Swamp: -1,
  Mountain: -1,
  Forest: -1,
  Wastes: -1,
  'Snow-Covered Plains': -1,
  'Snow-Covered Island': -1,
  'Snow-Covered Swamp': -1,
  'Snow-Covered Mountain': -1,
  'Snow-Covered Forest': -1,
  'Snow-Covered Wastes': -1,
  'Relentless Rats': -1,
  'Rat Colony': -1,
  'Shadowborn Apostle': -1,
  'Persistent Petitioners': -1,
  "Dragon's Approach": -1,
  'Slime Against Humanity': -1,
  'Templar Knight': -1,
  'Hare Apparent': -1,
}

export const COMMANDER_OVERRIDE = ['Grist, the Hunger Tide']

export const SINGLE_EXTRA_COLOR_COMMANDERS = ['The Prismatic Piper', 'Faceless One', 'Clara Oswald']
