import styled from 'styled-components'

export const LegalityLabel = styled.div<{ legality: 'legal' | 'banned' | 'restricted' | 'not_legal'; small?: boolean }>`
  width: 90px;
  font-size: 70%;
  height: 20px;
  border-radius: 8px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  background: ${props =>
    props.legality === 'legal'
      ? '#3d8f52'
      : props.legality === 'banned'
      ? '#783131'
      : props.legality === 'restricted'
      ? '#999049'
      : '#6b6b6b'};
  color: white;
  ${props =>
    props.small
      ? `
    width: 60px;
    height: 20px;
    font-size: 70%;
    `
      : ``}
`

export const LegalityBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-around;
  justify-content: center;
`
