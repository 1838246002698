import { TextColumns } from 'redux/cacheables/initialState'

export const COLUMN_WIDTHS: Record<'name' | keyof TextColumns, number> = {
  name: 222, // includes quantity and controls menu
  setSymbol: 50,
  price: 50,
  dragHandle: 10,
  colorTag: 22,
  collectionInfo: 10,
  manaCost: 120,
  extraOptions: 10,
  finishes: 10,
  canlanderPoints: 10,

  // Depricated (hopefully)
  type: 200,
  setName: 150,
}
