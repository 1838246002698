import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBluesky } from '@fortawesome/free-brands-svg-icons'

import Icon from 'components/elements/Icon'

import styles from './externalLinks.module.scss'

const ExternalLinks = () => {
  return (
    <div className={styles.container}>
      <a href="https://discord.gg/GyAAANU8uJ" rel="noopener noreferrer" target="_blank">
        <Icon name="discord" />
      </a>
      <a href="https://www.facebook.com/archidekt/" rel="noopener noreferrer" target="_blank">
        <Icon name="facebook" />
      </a>
      <a
        href="https://bsky.app/profile/archidekt.com"
        rel="noopener noreferrer"
        target="_blank"
        className={styles.blueskyFontAwesomeAdjustment}>
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={faBluesky} />
      </a>
      <a href="https://www.twitch.tv/archidekt" rel="noopener noreferrer" target="_blank">
        <Icon name="twitch" />
      </a>
      <a href="https://www.youtube.com/channel/UC__St4xQR9vUipooUZFeNNw" rel="noopener noreferrer" target="_blank">
        <Icon name="youtube" />
      </a>
      <a href="https://www.patreon.com/archidekt" rel="noopener noreferrer" target="_blank">
        <Icon name="patreon" />
      </a>
    </div>
  )
}

export default ExternalLinks
