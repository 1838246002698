import React from 'react'
import ReactGA from 'react-ga4'
import Icon from 'components/elements/Icon'

import { CardType } from 'types/deck'
import { PRICE_ICON_NAMES, PRICE_NAMES, PRICE_SOURCE } from 'types/active'

import { useAppSelector } from 'redux/hooks'

import { getPriceStringAndUrl } from 'utils/Price'

import styles from './prices.module.scss'

type Props = {
  card: CardType
  className?: string
}

const Prices = ({ card, className = '' }: Props) => {
  const priceSource = useAppSelector(state => state.active.priceSource)

  return (
    <div className={`${styles.container} ${className}`}>
      <Price card={card} priceSource={priceSource[0]} />
      <Price card={card} priceSource={priceSource[1]} className={styles.priceTwo} />
    </div>
  )
}

export default Prices

type PriceProps = {
  card: CardType
  priceSource: number
  className?: string
}

export const Price = ({ card, priceSource, className = '' }: PriceProps) => {
  const { price, url, background, affiliateName } = getPriceStringAndUrl(card, priceSource, card.modifier !== 'Normal')
  const priceName = PRICE_NAMES[priceSource]

  const writeAnalytics = () => {
    ReactGA.event({
      category: 'Deck',
      action: `Opened storefront ${priceName} (single)`,
      label: `affiliate=${affiliateName}`,
    })
  }

  return (
    <a
      className={`${styles.price} ${className}`}
      title={priceName}
      href={url}
      onClick={writeAnalytics}
      rel="noopener noreferrer"
      tabIndex={priceSource === PRICE_SOURCE.NONE ? -1 : undefined}
      target="_blank">
      <Icon className={PRICE_ICON_NAMES[priceSource]} style={{ color: background }} />{' '}
      {priceSource === PRICE_SOURCE.NONE ? null : price}
    </a>
  )
}
