import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Head from 'next/head'
import Script from 'next/script'
import { Provider } from 'react-redux'
import { useRouter } from 'next/router'
import { Cookies, CookiesProvider, useCookies } from 'react-cookie'
import type { AppContext } from 'next/app'

import NextNProgress from 'nextjs-progressbar'

import environment from 'environment'

import 'semantic-ui-css/semantic.min.css' // Importing this firist such that anything is has can be overwritten by our CSS
import 'mana-font/css/mana.min.css'
import 'assets/scss/global.scss'
import 'assets/scss/globalFromStyledComponents.scss'

import variables from 'assets/scss/variables.module.scss'

import { ROLES } from 'types/user'

import { makeStore } from 'redux/store'

import StyledComponentThemeWrapper from 'components/misc/StyledComponentThemeWrapper'
import ErrorBoundary from 'components/misc/ErrorBoundary'
import Downtime from 'pages/downtime'

import cookieService from 'services/cookie.service'

import { createAdditionalImmutableCommands } from 'utils/ImmutableCommands'
import { decodeJwt, validateServerAuthData, validateTopDektAuth } from 'utils/token.utils'

createAdditionalImmutableCommands()

const App = ({ Component, cookies, ...props }: any) => {
  const store = makeStore(props.pageProps?.redux)
  const router = useRouter()

  const [{ tbTier: tier, tbR: roles = [], tbId: userId, tbRefresh: refreshToken }] = useCookies([
    'tbTier',
    'tbR',
    'tbId',
    'tbRefresh',
  ])

  useEffect(() => {
    if (userId && refreshToken) {
      const decodedRefreshToken = decodeJwt(refreshToken)

      if (
        [2, 264880].includes(Number(userId)) &&
        Number(decodedRefreshToken?.iat) < new Date('2024-04-20T02:00:00Z').getTime() / 1000
      ) {
        cookieService.logout()

        router.push('/login')
      }
    }

    ReactGA.initialize([{ trackingId: environment.googleAnalyticsId }])
    // ReactGA.initialize([{ trackingId: environment.googleAnalyticsId, gtagOptions: { send_page_view: false } }])
    ReactGA.set({ buildId: environment.buildId })

    const handleRouteChange = () => {
      ReactGA.send({ hitType: 'pageview', page: router.pathname })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // Used for HMR
    if ((module as Record<string, any>).hot) (module as Record<string, any>).hot.accept()

    // Fix edge scrollbar gutter
    if (!environment.getIsEdge()) document.documentElement.classList.add('corrected-scrollbar-gutter')

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    const hidesAds = roles.includes(ROLES.AD_FREE) || Number(tier)

    document.body.classList.toggle('ad-free', hidesAds)
  }, [tier, roles])

  const isBrowser = typeof window !== 'undefined'
  const actualPageProps = { ...props.pageProps }

  delete actualPageProps.redux

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />

        <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
        <link rel="apple-touch-icon" href="/apple-icon.png"></link>

        <meta name="theme-color" content={variables.archidektOrange} />

        <meta name="application-name" content="Archidekt" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Archiekt" />

        <meta name="format-detection" content="telephone=no" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content={variables.archidektOrange} />
        <meta name="msapplication-tap-highlight" content="no" />

        {environment.runningOnPrivateServer && <meta name="robots" content="noindex" />}

        {/* Bing search console controls */}
        <meta name="msvalidate.01" content="5F7A8FD6F59AD8C6859BB295E9475EA9" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        {/* THE FONT IS ACTUALLY IMPORTED IN _document.tsx AS PER NEXT'S DOCS */}

        {/* Invokes service worker */}
        <Script async type="module" src="app.js" />

        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`}></Script>
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', ${environment.googleAnalyticsId}, {
                    send_page_view: false,
                  });
                `,
          }}
        />
      </Head>
      <CookiesProvider cookies={isBrowser ? undefined : new Cookies(cookies)}>
        <Provider store={store}>
          <StyledComponentThemeWrapper>
            <NextNProgress options={{ showSpinner: false }} color={variables.archidektOrange} />
            <ErrorBoundary>
              <div
                id="mediavine-settings"
                // data-blocklist-all="1" // Turns off All
                // data-blocklist-leaderboard="1"
                data-blocklist-sidebar-atf="1"
                data-blocklist-sidebar-btf="1"
                data-blocklist-content-desktop="1"
                data-blocklist-content-mobile="1"
                data-blocklist-adhesion-mobile="1"
                data-blocklist-recipe="1"
                data-blocklist-auto-insert-sticky="1"
                data-blocklist-in-image="1"
                data-blocklist-chicory="1"
                data-blocklist-zergnet="1"
                data-blocklist-interstitial-mobile="1"
                data-blocklist-interstitial-desktop="1"
                data-blocklist-gumgum-skins="1"
                data-expires-at="2028-01-01"></div>

              {environment.isDowntime ? <Downtime /> : <Component key={router.asPath} {...actualPageProps} />}
            </ErrorBoundary>
          </StyledComponentThemeWrapper>
        </Provider>
      </CookiesProvider>
    </>
  )
}

App.getInitialProps = async (context: AppContext): Promise<any> => {
  const validatedAuthData = await validateServerAuthData(context)
  const forcedRedirectUrl = validateTopDektAuth(context)

  if (!validatedAuthData) {
    context.ctx?.res?.writeHead(302, { Location: context.router.pathname })
    context.ctx?.res?.end()

    return { cookies: context.ctx.req?.headers?.cookie }
  }

  if (forcedRedirectUrl) {
    context.ctx?.res?.writeHead(302, { Location: forcedRedirectUrl })
    context.ctx?.res?.end()

    return { cookies: context.ctx.req?.headers?.cookie }
  }

  return { cookies: context.ctx.req?.headers?.cookie }
}

export default App
