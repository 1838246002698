import React from 'react'

import SimplePageWrapper from 'components/misc/SimplePageWrapper'

import styles from './downtime.module.scss'

type Props = {}

const DowntimePage = ({}: Props) => (
  <SimplePageWrapper
    noLinks
    noAccount
    title="Archidekt - Maintenance"
    description="We are offline, Archidekt will be back soon! Check out our Discord or Bluesky for more updates.">
    <div className={styles.container}>
      <div className={styles.branding}>
        <img alt="Archidekt basic logo" src="/images/archidekt_basic.svg" height="100" />
      </div>

      <h1>Archidekt is currently offline for maintenance</h1>
      <h3>We should be back soon!</h3>

      <p>
        If you want to keep tabs on things to figure out when we'll be back, check out our{' '}
        <a href="https://discord.gg/GyAAANU8uJ">Discord</a> or our{' '}
        <a href="https://bsky.app/profile/archidekt.com">Bluesky</a>. Thank you for your patience!
      </p>
    </div>
  </SimplePageWrapper>
)

export default DowntimePage
