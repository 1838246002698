import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { isEqual } from 'lodash'
import Icon from 'components/elements/Icon'
import shortid from 'shortid'

import { SET_COLLECTION_V2_STATE } from 'redux/collectionV2/actions/types'

import { useActionless, useAppSelector } from 'redux/hooks'
import { CollectionTag, CollectionV2Card } from 'redux/collectionV2/types'
import { Option } from 'components/elements/ArchidektDropdown/types'

import CollectionService from 'services/collection.service'
import ToastService from 'services/toast.service'

import LabelDisplay from 'components/collectionV2/misc/LabelDisplay'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import EditLabelsModal from 'components/collectionV2/forms/EditLabelsModal'

import styles from './labelOptions.module.scss'
import PhatInput from 'components/formElements/PhatInput'

type Props = {
  collectionCard: CollectionV2Card
  onChange: (updatedCollectionCard: CollectionV2Card) => void
  className?: string
}

const sortTagIdByName = (tagNameA: string, tagNameB: string) => {
  const aName = tagNameA?.toLowerCase()
  const bName = tagNameB?.toLowerCase()

  if (aName < bName) return -1
  if (aName > bName) return 1

  return 0
}

const LabelOptions = ({ collectionCard, onChange, className = '' }: Props) => {
  const tags = useAppSelector(state => state.collectionV2.tags)
  const hasFetchedCollectionTags = useAppSelector(state => state.collectionV2.hasFetchedCollectionTags)

  const [setCollectionV2State] = useActionless(SET_COLLECTION_V2_STATE)

  const [{ tbId: userId }] = useCookies(['tbId'])

  const [loading, setLoading] = useState(false)
  const [editLabelsModalOpen, setEditLabelsModalOpen] = useState(false)
  const [fetchingTags, setFetchingTags] = useState(false)

  const handleSaveChanges = (tags: number[]) => {
    if (isEqual(collectionCard.tags, tags)) return

    onChange({ ...collectionCard, tags })

    setLoading(true)
  }

  useEffect(() => {
    if (loading) setLoading(false)
  }, [collectionCard.tags])

  useEffect(() => {
    if (hasFetchedCollectionTags) return

    setFetchingTags(true)

    // hardcoding oracleIds=0 to get all tags and none of the cards. We should probably just have a dedicated endpoint for fetching tags
    CollectionService.listV2(`${userId}`, 'oracleCardIds=0')
      .then(res => {
        const tags = res.tags.reduce((acc, tag) => {
          acc[tag.id] = tag

          return acc
        }, {} as Record<number, CollectionTag>)

        setCollectionV2State({ tags, hasFetchedCollectionTags: true })
      })
      .catch(() => ToastService.create('Collection labels could not be fetched', 'Collection Service Error', 'error'))
      .finally(() => setFetchingTags(false))
  }, [])

  const displayTags = collectionCard.tags
    .filter(tagId => !!tags[tagId]) // Filter out any tags that may have been deleted
    .sort((a, b) => sortTagIdByName(tags[a]?.name, tags[b]?.name))

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <label>Collection labels</label>

        <button onClick={() => setEditLabelsModalOpen(true)}>
          <Icon name="tags" />
          Create / edit labels
        </button>
      </div>

      <LabelOptionsDropdown
        onTagsUpdated={handleSaveChanges}
        cardTags={collectionCard.tags}
        triggerClassName={styles.dropdownTrigger}>
        <Icon name="add circle" />
        Select labels for <b>{collectionCard.card.name}</b>
      </LabelOptionsDropdown>

      {loading && (
        <div className={styles.noLabels}>
          <SimpleSpinner size="medXSmall" /> Saving collection labels...
        </div>
      )}

      {fetchingTags && (
        <div className={styles.noLabels}>
          <SimpleSpinner size="medXSmall" /> Fetching collection labels...
        </div>
      )}

      {!!displayTags.length && !loading && (
        <div className={styles.appliedTags}>
          {displayTags.map(tagId => (
            <LabelOption key={tagId} name={tags[tagId]?.name} color={tags[tagId]?.color} />
          ))}
        </div>
      )}

      {!displayTags.length && !loading && <div className={styles.noLabels}>No labels applied</div>}

      <EditLabelsModal open={editLabelsModalOpen} onClose={() => setEditLabelsModalOpen(false)} />
    </div>
  )
}

export default LabelOptions

type LabelOptionsDropdownProps = {
  children: React.ReactNode
  onTagsUpdated: (updatedTags: number[]) => void
  cardTags: number[]
  triggerClassName?: string
  disabled?: boolean
}
export const LabelOptionsDropdown = ({
  onTagsUpdated,
  children,
  cardTags,
  triggerClassName,
  disabled = false,
}: LabelOptionsDropdownProps) => {
  const tags = useAppSelector(state => state.collectionV2.tags)
  const optionTags = Object.keys(tags).sort(sortTagIdByName)

  const [tagNameFilter, setTagNameFilter] = useState('')
  const [localTags, setLocalTags] = useState(cardTags)
  const [focused, setFocused] = useState(false)

  const handleChange = (checked: boolean, tagId: number | string) => {
    const updatedTags = checked ? [...localTags, Number(tagId)] : localTags.filter(id => Number(id) !== Number(tagId))

    setLocalTags(updatedTags)
  }

  useEffect(() => {
    if (!isEqual(localTags, cardTags)) setLocalTags(cardTags)
  }, [cardTags])

  const options: Option[] = optionTags.length
    ? optionTags
        .filter(tagId => {
          if (!tagNameFilter.length) return true

          return tags[tagId]?.name?.toLocaleLowerCase().includes(tagNameFilter.toLocaleLowerCase())
        })
        .map(tagId => ({
          type: 'custom',
          customChild: (
            <LabelOption
              id={tagId}
              name={tags[tagId]?.name}
              color={tags[tagId]?.color}
              value={localTags.includes(Number(tagId))}
              onChange={checked => handleChange(checked, tagId)}
            />
          ),
        }))
    : [
        {
          label: 'Create labels before adding them to cards',
          className: styles.noLabelsMessage,
          disabled: true,
          onClick: () => null,
        },
      ]

  if (!focused)
    return (
      <button
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onClick={() => setFocused(true)}
        className={`${styles.defaultTriggerStyles} ${triggerClassName}`}>
        {children}
      </button>
    )

  return (
    <ArchidektDropdown
      focusedOnMount
      disabled={disabled}
      menuClassName={styles.menu}
      onBlur={() => {
        onTagsUpdated(localTags)
        setTagNameFilter('')
        setFocused(false)
      }}
      triggerClassName={styles.labelsDropdown}
      options={options}>
      <PhatInput
        focusOnMount
        placeholder="Select or filter for labels..."
        value={tagNameFilter}
        onChange={setTagNameFilter}
        className={`${styles.defaultTriggerStyles} ${triggerClassName}`}
      />
    </ArchidektDropdown>
  )
}

const LabelOption = ({
  name,
  color,
  value = false,
  id,
  onChange,
}: {
  name: string
  color: string
  value?: boolean
  id?: string | number
  onChange?: (selected: boolean) => void
}) => {
  const [elementId] = useState(shortid.generate())

  return (
    <div className={styles.labelOption}>
      <LabelDisplay className={styles.colorLabel} htmlFor={elementId} name={name} color={color} />
      {onChange && <input id={elementId} type="checkbox" onChange={e => onChange(e.target.checked)} checked={value} />}
    </div>
  )
}
