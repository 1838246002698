import React, { useEffect, useState } from 'react'

import PhatDropdown from 'components/formElements/PhatDropdown'
import LocalSettingToggle from 'components/accountSettingsPage/BrowserSpecificSiteSettings/LocalSettingToggle'

import {
  getImageSizePreference,
  setImageSizePreference,
  hideDefaultColorTagsKey,
} from 'services/accountSettings.service'

import styles from './browserSpecificSiteSettings.module.scss'

import { ImageSize } from 'utils/ImageUrl'

// Local storage keys
export const leftHandControlsKey = 'left-hand-controls'
export const disableCollaborativeToastsKey = 'disable-collaborative-toasts'
export const enableTouchDragDeckPageKey = 'enable-touch-drag-deck-page'
export const rightCommanderKey = 'right-commander'
export const stickyDeckSearchKey = 'sticky-deck-search'
export const disableCloseOnClickKey = 'disableCloseOnClick'
export const disableCursorFollowCard = 'disableHoverCard'

// Cookie keys
export const disableFoilLayerKey = 'tb-disable-foil-layer'
export const disableExtraLayerKey = 'tb-disable-extra-layers'
export const descriptionToTop = 'tb-description-to-top'

const BrowserSpecificSiteSettings = () => {
  const [localImagePreference, setLocalImagePreference] = useState<ImageSize>('normal')

  useEffect(() => {
    setLocalImagePreference(getImageSizePreference())
  }, [])

  const handleSetImagePreference = (value: ImageSize) => {
    setLocalImagePreference(value)
    setImageSizePreference(value)
  }

  return (
    <>
      <div className={styles.container}>
        <h2>Browser Specific Settings</h2>
        <p>
          These settings are created to be browser specific. That means that they will not follow your account around,
          but rather only be active to this specific web browser <b>only</b>. If you clear your cache, these settings
          will need to be set again.
        </p>

        <p>
          The purpose of these kinds of settings is to allow you to tailor your experiance on Archidekt on a per device
          level (eg: phone vs desktop).
        </p>

        <div className={styles.options}>
          <LocalSettingToggle
            header="Left Handed Controls"
            label="Card controls move to the left on the deck page"
            cacheKey={leftHandControlsKey}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Disable collaborative messages"
            label="Disable the messages that appear when someone else makes a change to a deck you're viewing"
            cacheKey={disableCollaborativeToastsKey}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Enable drag and drop via touch input on deck pages"
            label="Enable drag and drop via a touch screen on deck pages (this was disabled by default to it being a generally bad experience)"
            cacheKey={enableTouchDragDeckPageKey}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Disable foil layer"
            label="Disabling the foil layer will make foils appear as normal cards"
            cacheKey={disableFoilLayerKey}
            cacheStyle="cookie"
          />

          <LocalSettingToggle
            header="Disable extra card layer animations"
            label="Disabling the animated layers on cards for selected cards, focused cards, tags, etc. (Deck page only)"
            cacheKey={disableExtraLayerKey}
            cacheStyle="cookie"
          />

          <LocalSettingToggle
            header="Disable cursor hover cards"
            label="Disables hovering your cursor over certain elements to display the image of a card. WARNING: This can make certain elements much less usful or never show certain card info. Use at your own risk."
            cacheKey={disableCursorFollowCard}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Move deck descriptions to the top"
            label="Moves the deck description above the deck content on deck pages. If the deck description is blank, it will still appear at the bottom of the page."
            cacheKey={descriptionToTop}
            cacheStyle="cookie"
          />

          <span>
            <label className={styles.label}>Image resolution preference</label>
            <p>
              Mobile or slower devices may run better with lower resolution images. Higher resolution images go{' '}
              <i>burrrrr</i>
            </p>
            <PhatDropdown
              inlineHeader
              value={localImagePreference}
              options={[
                { label: 'Small', onClick: () => handleSetImagePreference('small'), id: 'small' },
                { label: 'Normal', onClick: () => handleSetImagePreference('normal'), id: 'normal' },
                { label: 'Large', onClick: () => handleSetImagePreference('large'), id: 'large' },
              ]}
            />
          </span>
        </div>
      </div>
      <div className={styles.container}>
        <h2>Experimental Site Settings</h2>
        <p>
          These are settings that we don't consider to be in their final form. They're stable and functional, but
          require either UI updates or additional supporting features to be fully finished.
        </p>

        <div className={styles.options}>
          <LocalSettingToggle
            header="Commander(s) on the Right"
            label="Move Commander (premium) categories above Sideboard/Maybeboard"
            cacheKey={rightCommanderKey}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Sticky Deck Search"
            label="Search bar always remains at the top of the page while searching for cards on the deck page"
            cacheKey={stickyDeckSearchKey}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Do not close dropdown on 2nd click"
            label="When clicking on an Archidekt dropdown, do not close the dropdown when clicking again"
            cacheKey={disableCloseOnClickKey}
            cacheStyle="localStorage"
          />

          <LocalSettingToggle
            header="Hide default color tags"
            label="Hide default color tags on the deck page"
            cacheKey={hideDefaultColorTagsKey}
            cacheStyle="cookie"
          />
        </div>
      </div>
    </>
  )
}

export default BrowserSpecificSiteSettings
