import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import Link from 'components/elements/Link'
import { DECK_PAGE_STYLES } from 'types/deck'

export const EditorContainer = styled.div`
  width: 80%;
  max-width: 1400px;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DeckSearchContainer = styled.div`
  width: 90%;
  max-width: 600px;
  flex: 1 1 auto;
  position: relative;
`

export const LimitedContainer = styled.div`
  width: 95%;
  max-width: 1200px;
`

export const DeckPageStyle = styled.div`
  height: 100%;
`

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  background-color: ${props => props.theme.backgroundColor};
  transition: background-color 0.15s linear;
  color: ${props => props.theme.color};
`

export const Body = styled.div`
  flex: 1 1 auto;
  display: flex;
  z-index: 0;
`

export const SwipeBody = styled.div<{ noPadding?: boolean; paddingLeft?: boolean }>`
  position: relative;
  height: 100%;
  padding-top: ${props => (props.noPadding ? 0 : '50px')};
  padding-left: ${props => (props.paddingLeft ? '50px' : 0)};
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  @media (max-height: 604px) {
    padding-left: ${props => (props.paddingLeft ? '100px' : 0)};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  width: 100%;
  position: relative;
`

export const DeckPageContent = styled(Content)`
  max-width: ${DECK_PAGE_STYLES.MAX_WIDTH}px;
  width: ${DECK_PAGE_STYLES.WIDTH_PERCENTAGE};
  margin: auto;
`

export const Spacer = styled.div<{ width?: number | string; height?: number | string }>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? props.height : 50)}px;
  min-height: ${props => (props.height ? props.height : 50)}px;
`

export const PlaytesterTop = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
`

export const PlaytesterBottom = styled.div<{ mobile?: boolean }>`
  flex: 1 1 ${props => (props.mobile ? '80px' : '160px')};
  display: flex;
  min-height: ${props => (props.mobile ? '80px' : '160px')};
  max-height: ${props => (props.mobile ? '80px' : '160px')};
  max-width: 100%;
  position: relative;
`

export const PlaytesterContainer = styled.div`
  height: calc(100vh - 50px);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const LatestDeckButton = styled.div<{ top?: boolean }>`
  position: absolute;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 0;
  background: rgba(71, 142, 88, 0.3);
  border-left: solid 2px rgb(71, 142, 88);
  color: #fff;
  cursor: pointer;
  padding: 5px;
  transition: background 0.2s ease-in-out;
  ${props =>
    props.top
      ? `
  top: 0;
  border-bottom: solid 2px rgb(71, 142, 88);
  border-radius: 0px 0px 0px 4px;`
      : `
  bottom: 0;
  border-top: solid 2px rgb(71, 142, 88);
  border-radius: 4px 0px 0px 0px;`}
  &:hover {
    background: rgba(71, 142, 88, 0.6);
  }
`

export const NoWrap = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ZonesPopUp = styled.div`
  position: absolute;
  display: flex;
  background-color: #000;
  right: 5px;
  top: -85px;
  border-radius: 7px;
  overflow: hidden;
  z-index: 1;
  height: 80px;
  box-shadow: 2px 2px 10px #000000;
`

export const ReturnToSandbox = styled(Link)`
  position: absolute;
  padding: 7px;
  cursor: pointer;
  background-color: #d5c315;
  border: 2px solid #baa914;
  color: white;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0.9;
  border-radius: 0px 0px 5px 0px;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.75;
  }
`

export const ButtonContainer = styled.div`
  width: 80%;
  max-width: 1400px;
  position: relative;
  height: 50px;
`

export const RightButton = styled(Button)`
  position: absolute;
  right: 0;
`

export const SmallBox = styled.div`
  max-width: 300px;
  width: 90%;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.14);
  padding: 10px;
  background-color: #fff;
`

export const RightAlign = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const TopContainer = styled.div`
  width: 100%;
  position: relative;
`

export const SideNitroContainer = styled.div<{ position?: string; top?: string; right?: string }>`
  width: 160px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${props => (props.position ? props.position : 'relative')};
  ${props => (props.right ? `right: ${props.right};` : ``)}
  ${props => (props.top ? `top: ${props.top};` : ``)}
  @media (max-width: 1000px) {
    display: none;
  }
`

export const FlexContainer = styled.div`
  display: flex;
`
