import React from 'react'

import { CardType } from 'types/deck'

import { fullTextToElement, manaArrayToElement } from 'components/misc/customIcons/ManaSymbol/helpers'
import { typeArraysToString } from 'utils/TypeString'

import { Info, SubInfo, SectionHeader, Flavor } from '../style'
import { DoubleDirection } from 'components/misc/StyledGridBackgrounds'

type Props = {
  card: CardType
}

/**
 * Displays information taken from the front of the card for those who would rather read text, than text on an image.
 */
const FromFace = ({ card }: Props) => (
  <Info>
    <SectionHeader>
      {card.name}
      {manaArrayToElement(card)}
    </SectionHeader>
    <DoubleDirection position="relative" width="100%" height="2px" deg="90" />
    <SubInfo>{typeArraysToString(card)}</SubInfo>
    <DoubleDirection position="relative" width="100%" height="2px" deg="90" />
    <SubInfo>{fullTextToElement(card.text)}</SubInfo>
    {card.flavor && <Flavor> {card.flavor} </Flavor>}
    <DoubleDirection position="relative" width="100%" height="2px" deg="90" />
    {card.power && card.toughness && (
      <Info>
        <SubInfo>
          {card.power}/{card.toughness}
        </SubInfo>
        <DoubleDirection position="relative" width="100%" height="2px" deg="90" />
      </Info>
    )}
  </Info>
)

export default FromFace
