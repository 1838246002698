import { getCachedState } from 'redux/cacheables/helpers/cacheStateChanges'
import { ADD_TYPES } from 'types/deck'

export type CachablesState = {
  textColumns: TextColumns

  imageSize: ImageSize
  disableMargins: boolean
  showEmptyCardStacks: boolean
  hideDefaultTypeCategories: boolean
  pinnedCategory: boolean
  draggableStacks: boolean
  hideOutOfDeckCategories: boolean

  viewableDeckControls: Record<StickiableControls, boolean>

  quickAddOptions: QuickAddOptions
}

export type QuickAddOptions = {
  addType: ADD_TYPES
  dontClearOnSelect: boolean
  smartQuickAdd: boolean
  incrementOnDuplicate: boolean
  matchExistingBy: 'name' | 'uid' | 'never'
  addToCategoryName: string
}

export type TextColumns = {
  manaCost: boolean
  type: boolean
  price: boolean
  setName: boolean
  setSymbol: boolean
  colorTag: boolean
  collectionInfo: boolean
  dragHandle: boolean
  finishes: boolean
  extraOptions: boolean
  canlanderPoints: boolean
}

type ImageSize = 1 | 2 | 3

export type StickiableControls =
  | 'cardSearch'
  | 'import'
  | 'export'
  | 'clone'
  | 'addToCollection'
  | 'optimize'
  | 'selectAll'
  | 'packages'
  | 'stats'
  | 'history'
  | 'settings'

export const generateInitialState = (serverCookies?: Record<string, any> | string): CachablesState => {
  const cachedState = getCachedState(serverCookies)

  return {
    textColumns: {
      dragHandle: cachedState?.textColumns?.dragHandle ?? true,
      extraOptions: cachedState?.textColumns?.extraOptions ?? true,
      manaCost: cachedState?.textColumns?.manaCost ?? true,
      type: cachedState?.textColumns?.type ?? false,
      price: cachedState?.textColumns?.price ?? false,
      setName: cachedState?.textColumns?.setName ?? false,
      setSymbol: cachedState?.textColumns?.setSymbol ?? false,
      colorTag: cachedState?.textColumns?.colorTag ?? false,
      collectionInfo: cachedState?.textColumns?.collectionInfo ?? false,
      finishes: cachedState?.textColumns?.finishes ?? false,
      canlanderPoints: cachedState?.textColumns?.canlanderPoints ?? false,
    },

    imageSize: cachedState?.imageSize ?? 2,
    disableMargins: cachedState?.disableMargins ?? false,
    showEmptyCardStacks: cachedState?.showEmptyCardStacks ?? false,
    hideDefaultTypeCategories: cachedState?.hideDefaultTypeCategories ?? false,
    pinnedCategory: cachedState?.pinnedCategory ?? false,
    draggableStacks: cachedState?.draggableStacks ?? false,
    hideOutOfDeckCategories: cachedState?.hideOutOfDeckCategories ?? false,

    viewableDeckControls: {
      cardSearch: cachedState?.viewableDeckControls?.cardSearch ?? false,
      import: cachedState?.viewableDeckControls?.import ?? false,
      export: cachedState?.viewableDeckControls?.export ?? false,
      clone: cachedState?.viewableDeckControls?.clone ?? false,
      addToCollection: cachedState?.viewableDeckControls?.addToCollection ?? false,
      optimize: cachedState?.viewableDeckControls?.optimize ?? false,
      selectAll: cachedState?.viewableDeckControls?.selectAll ?? false,
      packages: cachedState?.viewableDeckControls?.packages ?? false,
      stats: cachedState?.viewableDeckControls?.stats ?? false,
      history: cachedState?.viewableDeckControls?.history ?? false,
      settings: cachedState?.viewableDeckControls?.settings ?? true,
    },

    quickAddOptions: {
      addType: cachedState?.quickAddOptions?.addType || 1,
      dontClearOnSelect: cachedState?.quickAddOptions?.dontClearOnSelect ?? false,
      smartQuickAdd: cachedState?.quickAddOptions?.smartQuickAdd ?? false,
      incrementOnDuplicate: cachedState?.quickAddOptions?.incrementOnDuplicate ?? false,
      matchExistingBy: cachedState?.quickAddOptions?.matchExistingBy || 'uid',
      addToCategoryName: cachedState?.quickAddOptions?.addToCategoryName || '',
    },
  }
}

export default generateInitialState
