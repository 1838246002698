import React from 'react'

import environment from 'environment'

import { store } from 'redux/store'

import RequestService from 'services/request.service'
import ToastService from 'services/toast.service'

import { PRICE_SOURCE } from 'types/active'
import { Option } from 'components/elements/ArchidektDropdown/types'

import PhatButton from 'components/formElements/PhatButton'
import Icon from 'components/elements/Icon'

import { getAffiliateParams } from 'utils/Price'
import { generateShoppingResults } from 'utils/Shopping'

import styles from './buyCardsLink.module.scss'

type Props = {
  cardIds: string[]
  priceSource: number
  linkedFrom: string
  label?: string
  children?: React.ReactNode
}

export const BuyCardsLink = ({ cardIds, priceSource, linkedFrom, label = '', children = null }: Props) => {
  const state = store.getState()

  const cards = cardIds.map(id => state.deck.cardMap[id])

  const {
    ckprice,
    tcgprice,
    chprice,
    tcgstring,
    ckstring,
    chelements,
    scgstring,
    scgprice,
    mpstring,
    mpprice,
    tcgLandPrice,
    tcgLandString,
  } = generateShoppingResults(cards)

  const affiliatParams = getAffiliateParams(linkedFrom)

  // prettier-ignore
  const tcgurl = `https://partner.tcgplayer.com/c/4913290/1830156/21018?partnerpropertyid=${environment.tcgPropertyId}&${affiliatParams.tcg}&u=${encodeURIComponent(`https://store.tcgplayer.com/massentry`)}`
  const ckurl = `https://www.cardkingdom.com/builder?${affiliatParams.ck}`
  const churl = `https://www.cardhoarder.com/decks/upload?${affiliatParams.mtgo}`
  const tcgLandUrl = `https://www.tcg.land/multisearch#/magic-the-gathering?format=archidekt&separator=|&lines=${tcgLandString}&utm_medium=affiliate&utm_source=${environment.tcgLandAffiliateUrlParam}&via=bulk-buy`

  // prettier-ignore
  const mpurl = `https://manapool.com/add-deck?${environment.manapoolAffiliateUrlParams}&deck=${urlSafeBase64Encode(mpstring)}&tm_arc_bulk=1`

  let url = ''
  let searchString = ''
  let childElements = undefined
  let price = 0.0
  let partner = ''

  if (priceSource === PRICE_SOURCE.CK) {
    url = ckurl
    searchString = ckstring
    childElements = undefined
    price = ckprice
    partner = 'archidekt'
  }

  if (priceSource === PRICE_SOURCE.TCG) {
    url = `${tcgurl}?c=${encodeURIComponent(tcgstring)}`
    searchString = tcgstring
    childElements = null
    price = tcgprice
    partner = 'Archidekt'
  }

  if (priceSource === PRICE_SOURCE.MTGO) {
    url = churl
    searchString = ''
    childElements = chelements
    price = chprice
    partner = 'archidekt'
  }

  if (priceSource === PRICE_SOURCE.SCG) {
    searchString = scgstring
    price = scgprice
  }

  if (priceSource === PRICE_SOURCE.MP) {
    url = mpurl
    price = mpprice
  }

  if (priceSource === PRICE_SOURCE.TCGLAND) {
    url = tcgLandUrl
    price = tcgLandPrice
  }

  if (!url && priceSource !== PRICE_SOURCE.SCG) return null

  const renderedChildren = children || (
    <button type="submit">
      <Icon name="shopping cart" /> {label || 'Buy selected cards'}
    </button>
  )

  return (
    <ExternalFormButtonOrLink
      asLink={priceSource === PRICE_SOURCE.TCG || priceSource === PRICE_SOURCE.MP}
      url={url}
      searchString={searchString}
      price={price}
      partner={partner}
      chelements={childElements}
      scgLink={priceSource === PRICE_SOURCE.SCG}>
      {renderedChildren}
    </ExternalFormButtonOrLink>
  )
}

export default BuyCardsLink

type ExternalFormButtonOrLinkProps = {
  url: string
  searchString: string
  price: number
  partner: string
  text?: string
  color?: string
  icon?: string
  chelements?: any
  scgLink?: boolean
  children?: React.ReactNode
  onClick?: () => void
  asLink?: boolean
}

const ExternalFormButtonOrLink = ({
  url,
  searchString,
  price,
  color,
  icon,
  partner,
  text,
  chelements,
  scgLink,
  children = null,
  onClick,
  asLink = false,
}: ExternalFormButtonOrLinkProps) => {
  const [loading, setLoading] = React.useState(false)

  const handleFetchSCGUrl = async () => {
    setLoading(true)

    RequestService.post<{ url: string }>('/api/cards/bulk-scg-purchase/', { data: searchString })
      .then(res => {
        window.open(`${res.url}&aff=${environment.scgAffiliateId}`, '_blank')?.focus()
      })
      .catch(() => ToastService.create('Unable to fetch SCG URL', 'Bulk buy', 'error'))
      .finally(() => setLoading(false))
  }

  if (asLink)
    return (
      <a href={url} target="_blank">
        {children}

        {!children && (
          <PhatButton style={{ backgroundColor: color }} onClick={onClick}>
            {icon && <Icon className={icon} />}
            {text}
            {price}
          </PhatButton>
        )}
      </a>
    )

  // @ts-ignore - I have no clue how to fix this
  if (scgLink && children) return React.cloneElement(children, { onClick: handleFetchSCGUrl, disabled: loading })

  return (
    <form method="post" action={url} target="_blank">
      {chelements === undefined ? (
        <>
          <input type="hidden" name="c" value={searchString} />
          <input type="hidden" name="partner" value={partner} />
        </>
      ) : (
        chelements
      )}

      {children}

      {!children && (
        <PhatButton style={{ backgroundColor: color }} onClick={onClick}>
          {icon && <Icon className={icon} />}
          {text}
          {price}
        </PhatButton>
      )}
    </form>
  )
}

export const buyFromDropdown = (cardIds: string[], priceSource: number, linkedFrom: string, label?: string): Option => {
  const customChild = <BuyCardsLink cardIds={cardIds} priceSource={priceSource} linkedFrom={linkedFrom} label={label} />

  return {
    type: 'custom',
    className: styles.option,
    customChild: customChild,
    hidden: !customChild,
  }
}

export const urlSafeBase64Encode = (str: string) =>
  btoa(unescape(encodeURIComponent(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
